<template>
  <app-timeline>
    <template v-for="(item,index) in data">
      <app-timeline-item
        v-if="item.type == 'Comment'"
        :key="index"
        :title="i18nT(`Comment by ${item.object.UserName}`)"
        :subtitle="item.object.Comment + ''"
        icon="MessageSquareIcon"
        :time="item.object.UpdatedAt | date"
        variant="success"
        :data="item.object"
        :onDelete="onDelete"
        :showDelete="false"
      />
      <app-timeline-item
        v-if="item.type == 'Interview'"
        :key="index"
        :title="i18nT(`${item.object.UserName} scheduled an interview`)"
        :subtitle="item.object.Comment + ''"
        icon="CalendarIcon"
        :time="item.object.CreatedAt | date"
        variant="danger"
        :data="item.object"
        :onDelete="onDelete"
        :when="trimWhen(item.object.InterviewDate)"
        :with_="item.object.UserName"
        :where="item.object.Location"
        :showDelete="false"
      />
      <app-timeline-item
        v-if="item.type == 'Rating'"
        :key="index"
        :title="`${item.object.UserName}`"
        :subtitle="item.object.Comment + ''"
        icon="StarIcon"
        :time="item.object.UpdatedAt | date"
        variant="success"
        :data="item.object"
        :onDelete="onDelete"
        :rating="item.object.Rating"
        :ratingType="ratingTypes[item.object.ScoreType]"
        :showDelete="false"
      />
      <app-timeline-item
        v-if="item.type == 'Todo'"
        :key="index"
        :title="`${item.object.UserName}`"
        :subtitle="item.object.Comment + ''"
        icon="ClipboardIcon"
        :time="item.object.UpdatedAt | date"
        variant="warning"
        :data="item.object"
        :onDelete="onDelete"
        :showDelete="false"
      />
    </template>
    
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'
import moment from 'moment'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
    methods: {
      niceDate(inputDate){
          return moment(inputDate).format("Do MMM mm:HH");
      },
      trimWhen(whenRange){
          let output = '';
          if(whenRange){
              output = whenRange.substring(0,19) + whenRange.substring(29,35);
          }
          return output;
      }
    },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      default: null
    },
    ratingTypes: {
      type: Object,
      default: () => {}
    }
  },
}
</script>
