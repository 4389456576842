<template>
  <app-timeline>
    <app-timeline-item
      v-for="(job,index) in data"
      :key="index"
      :title="`${job.Label} | ${job.CompanyName}`"
      :subtitle="job.Description"
      icon="TruckIcon"
      variant="success"
      :time="formatDate(job.StartDate)+' - '+formatDate(job.EndDate)"
      :showDelete="false"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'
import useAuth from "@/auth/useAuth";
import moment from "moment/moment";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
      formatDate(date) {
          const dateFormat = useAuth.getDateFormat() || 'DD/MM/YYYY';
          const formatted = moment(new Date(date)).format(dateFormat);
          return formatted;
      },
  }
}
</script>
