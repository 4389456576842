<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Social links`) }}</h6>
        <div class="detail-field pt-1">
            <b-row v-if="hasSocials">
                <b-col cols="6">
                    <div v-if="candidateDetails.Linkedin" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Linkedin" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'linkedin']" size="lg" class="mr-1"/>
                            <span>Linked-In</span>
                        </b-link>
                    </div>
                   
                    <div v-if="candidateDetails.Instagram" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Instagram" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'instagram']" size="lg"  class="mr-1"/>
                            Instagram
                        </b-link>
                    </div>
                    <div v-if="candidateDetails.Web" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Web" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fa', 'globe']" size="lg" class="mr-1"/>
                            <span>Website</span>
                        </b-link>
                    </div>
                    <div v-if="candidateDetails.Tiktok" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Tiktok" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'tiktok']" size="lg" class="mr-1"/>
                            TikTok
                        </b-link>
                    </div>
                    <div v-if="candidateDetails.Github" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Github" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'github']" size="lg" class="mr-1"/>
                            GitHub
                        </b-link>
                    </div>
                </b-col>

                <b-col cols="6">
                    <div v-if="candidateDetails.Youtube" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Youtube" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'youtube']" size="lg" class="mr-1"/>
                            YouTube
                        </b-link>
                    </div>
                    <div v-if="candidateDetails.Twitter" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Twitter" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'twitter']" size="lg" class="mr-1"/>
                            Twitter
                        </b-link>
                    </div>
                    <div v-if="candidateDetails.Behance" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Behance" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'behance']" size="lg" class="mr-1"/>
                            Behance
                        </b-link>
                    </div>
                    <div v-if="candidateDetails.Dribble" class="sk-social-link mb-1">
                        <b-link :href="candidateDetails.Dribble" target="_blank" class="d-flex align-content-center align-items-center">
                            <font-awesome-icon :icon="['fab', 'dribbble']" size="lg"  class="mr-1"/>
                            Dribbble
                        </b-link>
                    </div>
                </b-col>

            </b-row>

            <div v-else class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No social media links`) }}</h5>
            </div>

        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BLink
} from 'bootstrap-vue';
export default {
    name: "CvSocialLinks",
    components: {
        BRow,
        BCol,
        BLink
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        hasSocials() {
            if(!this.candidateDetails) {
                return false;
            }else {
                return this.candidateDetails.Linkedin ||
                    this.candidateDetails.Youtube ||
                    this.candidateDetails.Instagram ||
                    this.candidateDetails.Dribble ||
                    this.candidateDetails.Web ||
                    this.candidateDetails.Twitter ||
                    this.candidateDetails.Behance ||
                    this.candidateDetails.Github ||
                    this.candidateDetails.Tiktok
            }

        }
    },
}
</script>

<style scoped>

</style>
