<template>
  <app-timeline>
    <app-timeline-item
      v-for="(rating,index) in data"
      :key="index"
      :title="`${rating.UserName}`"
      :subtitle="rating.Comment + ''"
      icon="StarIcon"
      :time="niceDate(rating.UpdatedAt)"
      variant="success"
      :data="rating"
      :onDelete="onDelete"
      :rating="rating.Rating"
      :ratingType="ratingTypes[rating.ScoreType]"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'
import moment from "moment";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      default: null
    },
    ratingTypes: {
      type: Object,
      default: () => {}
    }
  },
    methods: {
        niceDate(inputDate){
            return moment(inputDate).format("Do MMM mm:HH");
        }
    },
}
</script>
