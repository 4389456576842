<template>
    <validation-observer :ref="formId">
        <b-row>
            <b-col cols="12">

                <b-form-group
                    :label="i18nT(`Date`)"
                    label-for="from"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                    >
                        <date-picker
                            v-model="interviewFrom"
                            name="date"
                            :state="errors.length > 0 ? false:null"
                        ></date-picker>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

            </b-col>

            <b-col cols="6">
                <b-form-group
                    :label="i18nT(`Interview start time`)"
                    label-for="from"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                    >
                        <b-form-timepicker
                            v-model="interviewFromTime"
                            minutes-step="15"
                            :state="errors.length > 0 ? false:null">
                        </b-form-timepicker>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group
                    :label="i18nT(`Duration`)"
                    label-for="duration"
                    class="required"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="duration"
                        rules="required"
                    >
                        <b-form-select
                            v-model="duration"
                            :options="durations"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <b-form-group
            :label="i18nT(`Location`)"
            label-for="Location"
            class="required"
        >
            <validation-provider
                #default="{ errors }"
                name="Location"
                rules="required"
            >
                <b-form-input
                    v-model="interviewLocation"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>

        <b-form-group
            :label="i18nT(`Participants`)+` (`+i18nT(`Ctrl+click to select multiple`)+`) `"
            label-for="with"
            class="required"
        >
            <validation-provider
                #default="{ errors }"
                name="With"
                rules="required"
            >
                <b-form-select
                    v-model="interviewWith"
                    :multiple="true"
                    :options="candidateDetails.staff.map((staff) => ({value: staff.Id, text: staff.Label}))"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>

        <b-form-group
            :label="i18nT(`Internal comments`)"
            label-for="comment"
        >
            <b-form-textarea
                id="textarea-interviewComment"
                v-model="interviewComment"
                :placeholder="i18nT(`Private notes (the candidate doesn't see these)`)"
                rows="3"
            />

        </b-form-group>

        <b-button
            size=""
            variant="primary"
            @click="publishInterview"
        >
            {{ i18nT(`Publish`) }}
        </b-button>

    </validation-observer>
</template>

<script>

import {BButton, BCol, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BFormTimepicker, BRow} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import DatePicker from '@core/components/DatePicker.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {dictToSelectArray} from "@core/utils/utils";

export default  {
    name: "InterviewAddForm",
    components: {
        BFormGroup,
        BFormSelect,
        BFormInput,
        BFormTextarea,
        BFormTimepicker,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BCol,
        BRow,
        DatePicker
    },
    props: {
        formId: {
            type: String,
            default: 'interviewForm'
        },
        candidateDetails: {
            type: Object,
            default:  {
                current_application: {},
                staff: []
            }
        },

    },
    created(){
        this.$http.get(`system/interviewDurations`).then(({data}) => {
            this.durations = dictToSelectArray(data.data)
        });
    },
    data() {
        return {
            interviewFrom: '',
            interviewFromTime: '08:00:00',
            interviewTo: '',
            interviewWith: [],
            interviewLocation: '',
            interviewComment: '',
            duration: 30,
            durations: []
        }
    },
    methods: {
        resetForm(){

            this.interviewFrom = '';
            this.interviewFromTime = '08:00:00';
            this.interviewTo = '';
            this.interviewWith = [];
            this.interviewLocation = '';
            this.interviewComment = '';
            this.duration = 30;
            this.$refs[this.formId].reset()
        },
        publishInterview() {
            this.$refs[this.formId].validate().then(success => {

                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.candidateDetails.current_application.Id
                    )
                    // formData.append('interview_when', `${this.interviewFrom} - ${this.interviewTo}`)

                    formData.append('StartDate', this.interviewFrom+' '+this.interviewFromTime)
                    formData.append('Duration', this.duration)
                    formData.append('interview_with', this.interviewWith)
                    formData.append('interview_location', this.interviewLocation)
                    formData.append('interview_comment', this.interviewComment)

                    this.$http
                        .post('candidates/addInterview', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                           this.resetForm();
                            this.$emit('added', res.data.data)
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.i18nT('Please fill all required fields'),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                }
            })
        },
    }
}
</script>


<style scoped lang="scss">

</style>
