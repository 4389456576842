<template>
    <div v-if="candidateDetails">

        <!-- JOB OFFER SECTION -->
        <b-card
            class="section-container p-2"
            no-body
        >
            <b-row>
                <b-col cols="12">
                    <h4 class="mb-1">{{ i18nT(`Job offer`) }}</h4>
                </b-col>
                <b-col cols="4">
                    <div
                        class="mr-1">

                        <v-select
                            v-if="selectedOfferId"
                            v-model="curOfferChoice"
                            :options="jobOffers"
                            :placeholder="i18nT(`Select a job position`)"
                            class="job-application-select"
                            label="title"
                        />
                    </div>
                </b-col>
                <b-col cols="3" class="pt-05">
                    <div v-if="currentApplication && currentApplication.OfferObj" class="d-inline h5">
                        <b-link :to="'/re/job-offers/view/'+currentApplication.OfferObj.Id" target="_blank">
                            <strong>
                                <feather-icon icon="ExternalLinkIcon" size="18"></feather-icon>
                                {{ i18nT(`See job offer details`) }}</strong></b-link>
                    </div>

                </b-col>
                <b-col cols="5">
                    <div class="d-flex

                    align-content-end
                    align-items-center
                    justify-content-between ">

                        <div class="d-flex control cursor-pointer"
                             :class="{ 'text-muted pointer-events-none': isFirstCandidate}"
                             @click="changeCandidate('prev')"
                        >
                            <feather-icon
                                :icon="'ChevronLeftIcon'"
                                size="17"
                                class="ml-75 cursor-pointer pt-05 text-primary"
                                :class="{ 'text-muted pointer-events-none': isFirstCandidate}"

                            />
                            <div class="d-inline-block">
                                <span :class="isFirstCandidate ?`text-muted` : `text-primary`">{{ i18nT(`Previous candidate`) }}</span>
                                <span class="d-block font-small-3 text-muted">{{ prevCandidateNames }}</span>
                            </div>

                        </div>


                        <!-- Show Next Mail -->
                        <div class="d-flex control cursor-pointer"
                            :class="{ 'text-muted pointer-events-none': isLastCandidate}"
                             @click="changeCandidate('next')"
                        >
                            <div class="d-inline-block">
                                <span :class="isLastCandidate ?`text-muted` : `text-primary`">{{ i18nT(`Next candidate`) }}</span>
                                <span class="d-block font-small-3 text-muted">{{ nextCandidateNames }}</span>
                            </div>
                            <feather-icon
                                :icon="'ChevronRightIcon'"
                                size="17"
                                class="ml-75 cursor-pointer text-primary"
                                :class="{'text-muted pointer-events-none': isLastCandidate}"
                            />
                        </div>

                    </div>

                </b-col>
            </b-row>

        </b-card>



        <b-row>
            <!-- JOB APPLICATION DETAILS AND TABS -->
            <b-col cols="7">
                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-body>

                        <div class="d-flex">
                            <div>
                                <h4 class="mb-1">{{ i18nT(`Application`) }}</h4>

                                <h5 class="d-inline-block mr-2">{{i18nT(`Status`)}}:</h5>

                                <b-dropdown
                                    class="d-inline"
                                    toggle-class=""
                                    variant="outline-secondary"
                                >
                                    <template #button-content>
                                        <b>{{candidateDetails.current_application ? candidateDetails.current_application.PublicationStatus : '' }}</b>
                                    </template>
                                    <b-dropdown-group
                                        id="dropdown-group-1"
                                        header="Application Status"
                                    >
                                        <b-dropdown-item
                                            v-for="stage in stages"
                                            :key="stage.label"
                                            @click="moveStage(stage)"
                                        >
                                            <span class="align-middle ml-50">{{ stage.label }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown-group>
                                </b-dropdown>
                            </div>
                        </div>
                        <hr class="invoice-spacing"/>

                        <!-- APPLICATION DETAILS -->
                        <b-tabs
                            vertical
                        >
                            <!-- CV -->
                            <b-tab class="text-left">
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="FileTextIcon"/>
                                        <span>{{ i18nT(`CV & Cover Letter`) }}</span>
                                    </div>
                                </template>
                                <div>

                                    <!-- CV -->
                                    <div v-if="candidateDetails &&
                                    candidateDetails.current_application &&
                                    candidateDetails.current_application.CvObj" class="tab-section">
                                        <h6 class="section-label">{{ i18nT(`CV`) }}</h6>
                                        <div class="detail-field">
                                            <div>
                                                <b-link :href="candidateDetails.current_application.CvObj.DownloadUrl">
                                                    {{ candidateDetails.current_application.CvObj.Label }}
                                                </b-link>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- COVER LETTER -->
                                    <div v-if="candidateDetails &&
                                    candidateDetails.current_application &&
                                     candidateDetails.current_application.CoverLetterObj" class="tab-section">
                                        <hr class="invoice-spacing"/>
                                        <h6 class="section-label">{{ i18nT(`Motivation letter`) }}</h6>
                                        <div class="detail-field">
                                            <div>
                                                <b-link
                                                    :href="candidateDetails.current_application.CoverLetterObj.DownloadUrl">
                                                    {{ candidateDetails.current_application.CoverLetterObj.Label }}
                                                </b-link>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </b-tab>

                            <!-- QUESTIONS -->

                            <b-tab v-if="currentApplication && currentApplication.Answers && currentApplication.Answers.length>0" >
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="FileTextIcon"/>
                                        <span>{{ i18nT(`Application questions`) }}</span>
                                    </div>
                                </template>
                                <!-- Question ANSWERS -->
                                <div>
                                    <div v-for="(oneAnswer, index) in currentApplication.Answers" :key="index"
                                         class="pt-1"
                                    >
                                        <h6>{{ index + 1 }}. {{ oneAnswer.Question }}</h6>
                                        <p>{{ oneAnswer.Label }}</p>
                                    </div>
                                </div>

                            </b-tab>

                            <!-- Timeline -->
                            <b-tab v-if="candidateDetails.tabs.timeline">
                                <template #title>
                                    <feather-icon icon="ListIcon"/>
                                    <span>{{ i18nT(`Timeline`) }}</span>
                                </template>
                                <div>
                                    <timeline
                                        :data="candidateDetails.tabs.timeline.data ? Object.values(candidateDetails.tabs.timeline.data): []"
                                        :ratingTypes="candidateDetails.lists.rating_types"
                                    />
                                </div>
                            </b-tab>

                            <!-- Tasks -->
                            <b-tab class="text-left">
                                <template #title>
                                    <div class="text-left">
                                        <feather-icon icon="CheckCircleIcon"/>
                                        <span>{{ i18nT(`Tasks`) }}</span>
                                    </div>
                                </template>
                                <div v-if="candidateDetails">
                                    <div>
                                        <p>{{
                                                i18nT(`Assign and manage tasks for this employee. You can add individual tasks or group
                                    templates.`)
                                            }}</p>
                                        <b-button
                                            size=""
                                            variant="primary"
                                            @click="openAssignTaskModal"
                                        >
                                            <feather-icon
                                                icon="PlusCircleIcon"
                                                size="16"
                                            />
                                            {{ i18nT(`Add new`) }}
                                        </b-button>
                                    </div>
                                    <div
                                        v-for="(task ,index) in candidateDetails.tabs.tasks.data"
                                        :key="index"
                                    >
                                        <div class="task-container d-flex">
                                            <div class="d-inline-block mt-1">
                                                <b-form-checkbox
                                                    :checked="task.Completed == 1"
                                                    :name="task.Id + ''"
                                                    @change="onFinishTask(task)"
                                                />
                                            </div>
                                            <div class="d-inline-block pl-1">
                                                <h5
                                                    class="task-title"
                                                    v-bind:class="{completed: task.Completed == 1}"
                                                >
                                                    {{ task.Label }}
                                                </h5>
                                                <span
                                                    class="task-type"
                                                    v-bind:class="{completed: task.Completed == 1}"
                                                >
                                                  {{ task.TaskType }}
                                                </span>

                                                <b-button
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                    class="removeBtn"
                                                    size="sm"
                                                    variant="outline-primary"
                                                    @click="removeTask(task)"
                                                >
                                                    <feather-icon
                                                        icon="Trash2Icon"
                                                        size="16"
                                                        style="margin: 0;"
                                                    />
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <!-- Comments -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="MessageSquareIcon"/>
                                    <span>{{ i18nT(`Comments`) }}</span>
                                </template>
                                <div v-if="candidateDetails">
                                    <comments
                                        :data="candidateDetails.tabs.comments.data"
                                        :onDelete="onDeleteComment"
                                        class="mt-2 mb-2"
                                    />
                                    <validation-observer ref="commentForm">
                                        <b-form-group
                                            :label="i18nT(`Comment`)"
                                            label-for="comment"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Comment"
                                                rules="required"
                                            >
                                                <b-form-textarea
                                                    id="textarea-comment"
                                                    v-model="comment"
                                                    :state="errors.length > 0 ? false:null"
                                                    rows="3"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <b-button
                                            size=""
                                            variant="primary"
                                            @click="publishComment"
                                        >
                                            {{ i18nT(`Publish`) }}
                                        </b-button>

                                    </validation-observer>
                                </div>
                            </b-tab>

                            <!-- Documents -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="FolderIcon"/>
                                    <span>{{ i18nT(`Documents`) }}</span>
                                </template>
                                <div v-if="candidateDetails">
                                    <div>
                                        <p>{{ i18nT(`Assign and manage documents for this candidate.`) }}</p>
                                        <b-button
                                            size=""
                                            variant="primary"
                                            @click="openAssignDocumentModal"
                                        >
                                            <feather-icon
                                                icon="PlusCircleIcon"
                                                size="16"
                                            />
                                            {{ i18nT(`Add new`) }}
                                        </b-button>
                                    </div>
                                    <documents
                                        v-if="candidateDetails.tabs.documents &&
                                candidateDetails.tabs.documents.data"
                                        :data="candidateDetails.tabs.documents.data"
                                        :onDelete="onDeleteDocument"
                                        class="mt-2 mb-2"
                                    />

                                </div>
                            </b-tab>

                            <!-- Interviews -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="CalendarIcon"/>
                                    <span>{{ i18nT(`Interviews`) }}</span>
                                </template>
                                <div class="mb-2">
                                    <p>{{ i18nT(`Create and manage interviews for this candidate.`) }}</p>
                                    <b-button
                                        size=""
                                        variant="primary"
                                        @click="openAddInterview"
                                    >
                                        <feather-icon
                                            icon="PlusCircleIcon"
                                            size="16"
                                        />
                                        {{ i18nT(`Add interview`) }}
                                    </b-button>
                                </div>
                                <interviews
                                    v-if="candidateDetails"
                                    :data="candidateDetails.current_application.Interviews"
                                    :onDelete="onDeleteInterview"
                                    class="mb-2"
                                />
                            </b-tab>

                            <!-- Ratings -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="StarIcon"/>
                                    <span>{{ i18nT(`Ratings`) }}</span>
                                </template>
                                <ratings
                                    :data="candidateDetails.tabs.ratings.data"
                                    :onDelete="onDeleteRating"
                                    :ratingTypes="candidateDetails.lists.rating_types"
                                    class="mt-2 mb-2"
                                />

                                <!-- RATINGS FORM -->
                                <validation-observer ref="ratingForm">
                                    <b-row>
                                        <b-col cols="6">
                                            <b-row>
                                                <b-col cols="12">
                                                    <b-form-group
                                                        :label="i18nT(`Rating Type`)"
                                                        label-for="ratingType"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Rating Type"
                                                            rules="required"
                                                        >
                                                            <b-form-select
                                                                v-model="ratingType"
                                                                :options="ratingTypes"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12">
                                                    <b-form-group
                                                        :label="i18nT(`Rating`)"
                                                        label-for="rating"
                                                    >
                                                        <b-form-rating v-model="rating"></b-form-rating>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-group
                                                :label="i18nT(`Comment`)"
                                                label-for="comment"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Comment"
                                                    rules="required"
                                                >
                                                    <b-form-textarea
                                                        id="textarea-ratingComment"
                                                        v-model="ratingComment"
                                                        :placeholder="i18nT(`Enter rating comment....`)"
                                                        :state="errors.length > 0 ? false:null"
                                                        rows="4"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-button
                                        size=""
                                        variant="primary"
                                        @click="publishRating"
                                    >
                                        {{ i18nT("Publish") }}
                                    </b-button>

                                </validation-observer>
                            </b-tab>
                            <!-- ToDos -->
                            <b-tab>
                                <template #title>
                                    <feather-icon icon="ClipboardIcon"/>
                                    <span> {{ i18nT("Private Notes") }}</span>
                                </template>
                                <todos
                                    :data="candidateDetails.tabs.todos.data"
                                    :onDelete="onDeleteTodo"
                                    class="mt-2 mb-2"
                                />
                                <validation-observer ref="todoForm">
                                    <b-form-group
                                        :label="i18nT(`Note`)"
                                        label-for="todo"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Note"
                                            rules="required"
                                        >
                                            <b-form-textarea
                                                id="textarea-default"
                                                v-model="todo"
                                                :state="errors.length > 0 ? false:null"
                                                rows="3"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-button
                                        size=""
                                        variant="primary"
                                        @click="publishTodo"
                                    >
                                        {{ i18nT(`Publish`) }}
                                    </b-button>

                                </validation-observer>
                            </b-tab>

                        </b-tabs>

                    </b-card-body>
                </b-card>
            </b-col>

            <!-- PROFILE INFO -->
            <b-col cols="5">
                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-header>
                        <h4 class="card-title">{{ i18nT(`Candidate`) }}</h4>
                        <b-dropdown
                            v-if="iCan('applications', 'write')"
                            no-caret
                            right
                            toggle-class="p-0"
                            variant="link"
                        >
                            <template #button-content>
                                <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                />
                            </template>
                            <b-dropdown-item
                                @click="navToEdit()"
                            >
                                <span class="align-middle ml-50">{{ i18nT(`Edit candidate`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="onDelete(candidateDetails.candidate.Id)">
                                <span
                                    class="align-middle ml-50"
                                    style="color: red"
                                >{{ i18nT(`Delete application`) }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-card-header>

                    <b-card-body>

                        <b-row>
                            <b-col cols="12" @click="navToView">
                                <div class="d-flex w-100">
                                    <b-avatar
                                        :src="candidateDetails.candidate.ImageUrl"
                                        :text="(candidateDetails.candidate.FirstName ? candidateDetails.candidate.FirstName.charAt(0) : '') + (candidateDetails.candidate.LastName ? candidateDetails.candidate.LastName.charAt(0) : '')"
                                        class="badge-light-danger mr-1 cursor-pointer"
                                        size="4rem"
                                    >
                                    </b-avatar>
                                    <div class="flex-grow-1">
                                        <h1 class="cursor-pointer">{{ candidateDetails.candidate.Label }}</h1>
                                        <div class="item-rating ml-0  cursor-pointer">
                                            <ul class="unstyled-list list-inline" v-b-tooltip.hover.top="i18nT(`Rating for position: `)+candidateRating.toFixed(1)">
                                                <li
                                                    v-for="star in 5"
                                                    :key="star"
                                                    class="ratings-list-item"
                                                    :class="{'ml-25': star-1}"
                                                >
                                                    <feather-icon
                                                        icon="StarIcon"
                                                        size="16"
                                                        :class="[{'fill-current': star <= candidateRating}, star <= candidateRating ? 'text-warning' : 'text-muted']"
                                                    />
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </b-col>

                        </b-row>

                        <b-row>
                            <b-col cols="6">
                                <p class="mb-0 mt-50">{{ candidateDetails.candidate.Position }}</p>
                                <p class="mb-0 mt-50">
                                    <small v-if="candidateDetails.candidate.Nationality" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'globe']"  style="margin-right: 2px;"/> {{ i18nT(candidateDetails.candidate.Nationality) | capFirst }} |
                                    </small>

                                    <small v-if="candidateDetails.candidate.Gender" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'venus-mars']"  style="margin-right: 2px;"/>{{ i18nT(candidateDetails.candidate.Gender) | capFirst }} |
                                    </small>

                                    <small v-if="candidateDetails.candidate.BirthDate" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'birthday-cake']" style="margin-right: 2px;"/> {{ candidateDetails.candidate.BirthDate | date }}
                                    </small>
                                </p>

                            </b-col>

                            <b-col cols="6">
                                <div class="info-item mt-50">
                                    <feather-icon
                                        icon="PhoneIcon"
                                        size="16"
                                    />
                                    <span>{{ candidateDetails.candidate.Phone || i18nT(`Not available`) }}</span>
                                </div>
                                <div class="info-item mt-50">
                                    <feather-icon
                                        icon="MailIcon"
                                        size="16"
                                    />
                                    <span>
                                        <b-link
                                            :href="'mailto:'+candidateDetails.candidate.Email"
                                            class="position-relative"
                                        >{{ candidateDetails.candidate.Email }}</b-link>
                                    </span>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- BIO / SUMMARY -->
                        <div v-if="candidateDetails.candidate && candidateDetails.candidate.Presentation">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Summary`) }}</h6>
                            <div class="detail-field">
                                {{ candidateDetails.candidate.Presentation }}
                            </div>
                        </div>

                        <!-- SOCIAL LINKS -->

                        <b-col md="6" sm="12" v-if="socialsList.find(social => candidateDetails.candidate[social])">
                            <cv-social-links :candidateDetails="candidateDetails.candidate"></cv-social-links>
                        </b-col>

                        <!-- Skills -->
                        <div v-if="candidateDetails.skills && candidateDetails.skills.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Skills`) }}</h6>
                            <div class="detail-field">
                                <b-row>
                                    <b-col cols="6">

                                    </b-col>
                                    <b-col cols="4"
                                           class="
                                           text-center
                                           text-uppercase
                                           text-muted
                                           font-small-2
                                            ">
                                        {{ i18nT(`Level`) }}
                                    </b-col>
                                </b-row>

                                <b-row
                                    v-for="(skill,index) in candidateDetails.skills"
                                    :key="index"
                                >
                                    <b-col cols="6">
                                        <span class="tag-container">
                                            {{ skill.Label }}
                                        </span>
                                    </b-col>
                                    <b-col cols="4" class="text-center pt-05">
                                        <b-progress
                                            :value="skill.LevelId"
                                            variant="primary"
                                            :max="3"></b-progress>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>

                        <!-- Languages -->

                        <div v-if="candidateDetails.languages && candidateDetails.languages.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Languages`) }}</h6>
                            <div class="detail-field px-1 pb-05">
                                <b-row>
                                    <b-col cols="4">

                                    </b-col>
                                    <b-col cols="4"
                                           class="
                                           text-center
                                           text-uppercase
                                           text-muted
                                           font-small-2
                                            ">
                                        {{ i18nT(`Spoken`) }}
                                    </b-col>
                                    <b-col cols="4"
                                           class="
                                           text-center
                                           text-uppercase
                                           text-muted
                                           font-small-2
                                            "
                                    >
                                        {{ i18nT(`Written`) }}
                                    </b-col>
                                </b-row>

                                <b-row v-for="(language,index) in candidateDetails.languages"
                                       :key="index"
                                        class="pb-05 pt-05"
                                       :class="{'border-bottom': index < candidateDetails.languages.length - 1}"
                                >
                                    <b-col cols="4">
                                        {{ language.Language }}
                                    </b-col>
                                    <b-col cols="4" class="text-center pt-05">
                                        <b-progress
                                            :value="language.SpeakId"
                                            variant="primary"
                                            :max="5"></b-progress>

                                    </b-col>
                                    <b-col cols="4" class="text-center pt-05">
                                        <b-progress
                                            :value="language.WriteId"
                                            variant="primary"
                                            :max="5"></b-progress>
                                    </b-col>
                                </b-row>


                            </div>
                        </div>

                        <!-- Experience -->
                        <div v-if="candidateDetails.jobs && candidateDetails.jobs.length >0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Work Experience`) }}</h6>
                            <div class="detail-field">
                                <jobs :data="candidateDetails.jobs"/>
                            </div>
                        </div>

                        <!-- Education -->
                        <div v-if="candidateDetails.education && candidateDetails.education.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Education`) }}</h6>
                            <div class="detail-field">
                                <educations :data="candidateDetails.education"/>
                            </div>
                        </div>

                        <!-- CERTIFICATES -->

                        <div v-if="candidateDetails.certificates && candidateDetails.certificates.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Certificates`) }}</h6>
                            <div class="detail-field px-1">
                                <b-row
                                    v-for="(certificate,index) in candidateDetails.certificates"
                                    :key="index"
                                >
                                    <b-col cols="12">
                                        <b-link :href="certificate.Url" target="_blank" >{{ certificate.Label }}</b-link>
                                    </b-col>
                                    <b-col cols="12">
                                        <p class="text-muted font-small-3">{{ certificate.Description}}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>


                        <!-- References -->
                        <div v-if="candidateDetails && candidateDetails.references && candidateDetails.references.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`References`) }}</h6>

                            <div class="detail-field px-1">
                                <b-row
                                    v-for="(ref,index) in candidateDetails.references"
                                    :key="index"
                                >
                                    <b-col v-if="ref.AttachedFile" cols="12">
                                        <b-link :href="ref.AttachedFile.DownloadUrl" target="_blank" >{{ ref.AttachedFile.Label }}</b-link>
                                    </b-col>
                                    <b-col v-else cols="12">
                                        <b-link :href="ref.Url" target="_blank" >{{ ref.Url }}</b-link>
                                    </b-col>
                                    <b-col cols="12">
                                        <p class="text-muted font-small-3">{{ref.Label}}</p>
                                    </b-col>
                                </b-row>
                            </div>


                        </div>

                        <!-- INTERESTS -->

                        <div v-if="candidateDetails.interests && candidateDetails.interests.length > 0">
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Interests`) }}</h6>
                            <div class="detail-field px-1">
                                <b-row
                                    v-for="(interest,index) in candidateDetails.interests"
                                    :key="index"
                                    class="pb-05 pt-05"
                                    :class="{'border-bottom': index < candidateDetails.interests.length - 1}"
                                >
                                    <b-col cols="12">
                                        <strong>{{ interest.Label}}</strong>
                                    </b-col>
                                    <b-col cols="12">
                                        <p class="text-muted font-small-3">{{ interest.Description}}</p>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>

                        <div>
                            <hr class="invoice-spacing"/>
                            <h6 class="section-label">{{ i18nT(`Relocation and remote work`) }}</h6>
                            <div class="detail-field px-1 pt-05">
                                <p>{{ i18nT(`Willing to relocate?`) }}: <strong>{{ candidateDetails.Relocate ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>
                                <p>{{ i18nT(`Willing to travel?`) }}: <strong>{{ candidateDetails.Travel ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>
                                <p>{{ i18nT(`Accepts remote work?`) }}: <strong>{{ candidateDetails.Remote ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>
                            </div>
                        </div>



                    </b-card-body>

                </b-card>
            </b-col>

        </b-row>


        <!-- MODAL -->
        <b-modal
            id="modal-add-interview"
            no-close-on-backdrop
            hide-footer
        >
            <interview-add-form
                :formId="'interviewForm'"
                :candidateDetails="candidateDetails"
                @added="interviewForModal"></interview-add-form>
        </b-modal>


        <b-modal
            id="modal-change-status"
            no-close-on-backdrop
            ok-only
            ok-title="Change Status"
            title="Change Status"
            @ok="validationChangeStatus"
        >
            <validation-observer ref="changeStatusForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-row
                                v-if="targetStage.type == 'HIRED'"
                                class="mb-2"
                            >
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Hiring date`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Hiring date"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="hiringDate"
                                                placeholder=""
                                                reset-button
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Evaluation Period End`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Evaluation Period End"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="evaluationPeriodEnd"
                                                placeholder=""
                                                reset-button
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Ending`)"
                                        label-for="message"
                                    >
                                        <date-picker
                                            v-model="ending"
                                            placeholder=""
                                            reset-button
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Net salary`)"
                                        label-for="message"
                                    >
                                        <b-form-input
                                            v-model="salaryNet"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Gross salary`)"
                                        label-for="message"
                                    >
                                        <b-form-input
                                            v-model="salaryGross"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`Type`)"
                                        label-for="message"
                                    >
                                        <b-form-select
                                            v-model="salaryType"
                                            :options="salaryTypes"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>


                            <b-row>
                                <b-col cols="12">
                                    <b-form-group v-if="targetStage.message_templates">
                                        <b-form-checkbox
                                            v-model="changeStatusNotification"
                                            :checked="changeStatusNotification"
                                            switch
                                        >
                                            {{ i18nT(`Send notification email?`) }}
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row
                                v-if="changeStatusNotification && targetStage.type == 'INTERVIEW'"
                                class="mb-2"
                            >
                                <b-col cols="12">
                                    <b-form-group
                                        :label="i18nT(`Select interview`)"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Interview"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="selectedInterview"
                                                :options="availableInterviews"
                                                :state="errors.length > 0 ? false : null"
                                                label="text"
                                            />
                                            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>

                                    </b-form-group>

                                    <span class="text-muted font-small-2">{{i18nT(`Is there no scheduled interview with this candidate yet?`)}} </span><span class="text-primary font-small-2 cursor-pointer" @click="openAddInterview"> {{i18nT(` Schedule an interview.`)}}</span>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12">
                                    <b-form-group
                                        v-if="changeStatusNotification && targetStage.message_templates"
                                        :label="i18nT(`Message Template`)"
                                        class="required"
                                        label-for="messageTemplate"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Message Template"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="statusChangeTemplate"
                                                :options="statusTemplateOptions"
                                                :state="errors.length > 0 ? false : null"
                                                label="text"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>

                                        <b-row class="pt-2">
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Subject`)"
                                                    label-for="emailSubject">
                                                    <b-form-input
                                                        name="emailSubject"
                                                        type="text" v-model="statusChangeSubject"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Email Message`)"
                                                    label-for="emailMessage">
                                                    <b-form-textarea
                                                        name="emailMessage"
                                                        v-model="statusChangeMessage"
                                                        :placeholder="i18nT(`Enter message`)"
                                                        rows="8">

                                                    </b-form-textarea>
                                                </b-form-group>

                                            </b-col>
                                            <b-col cols="12">
                                                <p class="font-small-3 text-muted">{{i18nT(`You can use the following placeholder tags which will be replaced by the actual data in the e-mail`)}}:</p>
                                                <p class="font-small-3 text-muted">
                                                    [candidate_first_name], [candidate_last_name], [candidate_full_name], [candidate_email],
                                                    [position_title], [position_url], [company_name], [employee_first_name], [employee_last_name], [employee_full_name], [employee_email], [interview_details]
                                                </p>
                                            </b-col>
                                        </b-row>

                                    </b-form-group>
                                </b-col>
                            </b-row>

                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL -->
        <b-modal
            id="modal-add-task"
            no-close-on-backdrop
            ok-only
            ok-title="Save"
            title="Assign Tasks"
            @ok="assignTasks"
        >
            <validation-observer ref="assignTaskForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <multiselect
                                v-model="tasks"
                                :group-select="true"
                                :label="i18nT(`Label`)"
                                :multiple="true"
                                :options="assignTaskList"
                                :placeholder="i18nT(`Type to search`)"
                                group-label="groupName"
                                group-values="options"
                                track-by="Label"
                            >
                                <span slot="noResult">
                                  {{ i18nT(`Oops! No elements found. Consider changing the search query.`) }}
                                </span>
                            </multiselect>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL -->
        <b-modal
            id="modal-add-document"
            no-close-on-backdrop
            ok-only
            ok-title="Save"
            title="Assign Document"
            @ok="assignDocuments"
        >
            <validation-observer ref="assignDocumentForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <multiselect
                                v-model="documents"
                                :group-select="true"
                                :label="i18nT(`Label`)"
                                :multiple="true"
                                :options="assignDocumentList"
                                :placeholder="i18nT(`Type to search`)"
                                group-label="groupName"
                                group-values="options"
                                track-by="Label"
                            >
                                <span slot="noResult">
                                  {{ i18nT(`Oops! No elements found. Consider changing the search query.`) }}
                                </span>
                            </multiselect>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

    </div>

</template>

<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BFormRating,
    BFormSelect,
    BFormInput,
    BModal,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BProgress,
    //BDropdownDivider,
    BForm,
    BFormCheckbox,
    BLink,
    VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Timeline from './Timeline.vue'
import Comments from './Comments.vue'
import Documents from './Documents.vue'
import Todos from './Todos.vue'
import Ratings from './Ratings.vue'
import Interviews from './Interviews.vue'
import Educations from './Educations.vue'
import Jobs from './Jobs.vue'
import router from '@/router'
import {
    dictToSelectArrayWithTitle,
    dictToSelectArray
} from '@core/utils/utils'
import listTasksFromJSON from './listTasks.json'
import Multiselect from 'vue-multiselect'
import CvSocialLinks from '../CVComponents/CvSocialLinks.vue'
import DatePicker from '@core/components/DatePicker.vue'
import vSelect from 'vue-select'
import InterviewAddForm from "@/views/recruiting/Candidates/InterviewAddForm.vue";
import useAuth from "@/auth/useAuth";
import moment from "moment/moment";

export default {
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BButton,
        BAvatar,
        BFormRating,
        BFormSelect,
        BProgress,
        //BFormDatepicker,
        BFormInput,
        Timeline,
        Comments,
        Documents,
        CvSocialLinks,
        Todos,
        Ratings,
        Interviews,
        Educations,
        Jobs,
        ValidationProvider,
        ValidationObserver,
        BModal,
        BDropdown,
        BDropdownItem,
        BDropdownGroup,
        //BDropdownDivider,
        Multiselect,
        BFormCheckbox,
        BLink,
        DatePicker,
        vSelect,
        InterviewAddForm
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            jobOffer: {},
            jobOffers: [],
            labelOptions: [],
            statusTemplateOptions: [],
            statusChangeMessage: '',
            statusChangeSubject: '',
            matchSkills: [],
            totalCandidates: 0,
            candidateIndex: -1,

            selectedInterview: null,

            curOfferChoice: {
                id: null,
                title: null
            },
            candidates: [],
            socialsList: [
                'Facebook',
                'Twitter',
                'Linkedin',
                'Instagram',
                'Youtube',
                'Pinterest',
                'Behance',
                'Github',
                'Dribble',
                'Web',
                'Tiktok'
            ],
            changeStatusNotification: false,
            selectedOfferId: null,
            lastOfferId: null,
            candidateDetails: {
                candidate: {},
                staff: [],
                tabs: {
                    timeline: {},
                    tasks: {},
                    interviews: {},
                    ratings: {},
                    todos: {},
                    comments: {},
                    documents: {}
                },
                current_application: {},
                offers: [],
                lists: {}
            },
            levels: [],
            required,
            comment: '',
            todo: '',
            ratingComment: '',
            ratingTypes: [],
            ratingType: '',
            rating: 0,
            interviewMailId: null,

            stages: [],
            offerOptions: [],
            folderOptions: [],
            targetStage: {},
            statusChangeTemplate: null,
            listTasksFromJSON,
            assignTaskList: [],
            tasks: [],
            documents: [],
            hiringDate: null,
            evaluationPeriodEnd: null,
            ending: null,
            salaryNet: null,
            salaryGross: null,
            salaryType: null,
            salaryTypes: [],
            assignDocumentList: [],
            duration: null,
            durations: [],
            selectedOffer: null,
            currentApplication: {
                OfferObj: {}
            }
        }
    },
    computed: {
        availableInterviews(){
            if(this.candidateDetails.tabs.interviews && this.candidateDetails.tabs.interviews.data){
                return this.candidateDetails.tabs.interviews.data.map(interview => ({
                    value: interview.Id,
                    text: interview.Location + ' - ' + this.formatDate(interview.StartDate)+' '+this.i18nT(`at`)+' '+ this.formatTime(interview.StartDate)
                }))
            }else {
                return [];
            }
        },
        candidateRating() {
            if(this.candidateDetails && this.candidateDetails.rating && this.candidateDetails.rating.rating) {
                return this.candidateDetails.rating.rating;
            }else {
                return 0;
            }
        },
        offerId() {
            return router.currentRoute.query.offerId
        },
        isLastCandidate(){
          return this.candidateIndex === (this.totalCandidates - 1);
        },
        nextCandidateNames(){
          if(this.candidates && this.candidates[this.candidateIndex + 1]){
            return this.candidates[this.candidateIndex + 1].Label;
          }
          return 'N/A';
        },
        isFirstCandidate(){
            return this.candidateIndex === 0;
        },
        prevCandidateNames(){
            if(this.candidates && this.candidates[this.candidateIndex - 1]){
                return this.candidates[this.candidateIndex - 1].Label;
            }
            return 'N/A';
        },
    },
    async created() {

        // ALL OFFERS
        this.$http.get(`offers/filterOffers`).then(({data}) => {
            this.jobOffers = dictToSelectArrayWithTitle(data.data)
        })

        let self = this;
        this.selectedOfferId = router.currentRoute.query.offerId;

        if (this.selectedOfferId) {
            await this.$http
                .get(`offers?id=${this.selectedOfferId}`)
                .then(({ data }) => {
                    let curOffer = data.data.offer;
                    self.curOfferChoice = {
                        value: curOffer.Id.toString(),
                        title: curOffer.Label
                    }

                    this.candidates = Object.values(data.data.candidates_applied);
                    this.totalCandidates = this.candidates.length;
                    this.candidateIndex = this.candidates.map(function(e) { return e.Id; }).indexOf(parseInt(this.$route.params.id));

                    if(this.candidateIndex > -1) { //CANDIDATE FOUND FOR THIS OFFER
                        //LOAD ALL THE DATA
                        this.refresh()
                    } else {
                        this.$router.push({name: 'applications'});
                    }

                })

        }else {
            //NO OFFER SELECTED
            this.$router.push({name: 'applications'});
        }

        //LOAD ALL ENUMS

        //RATING TEMPLATES
        this.$http.get(`settings/ratingTemplates`).then(({data}) => {
            this.ratingTypes = data.data.map(ratingType => ({
                value: ratingType.Id,
                text: ratingType.Label
            }))

            this.ratingType = this.ratingTypes[0].value
        })

        //STAGES
        this.$http.get(`candidates/stages`).then(({data}) => {
            let self = this
            if (data.data) {
                data.data.forEach(function (oneStage) {
                    if (oneStage.short !== '' && oneStage.short !== 'SOURCED') {
                        self.stages.push(oneStage)
                    }
                })
            }
        })

        // TASKS
        this.$http.get(`tasks/listForAssign`).then(({data}) => {
            this.assignTaskList = []
            const keys = Object.keys(data.data)
            for (const key of keys) {
                this.assignTaskList.push({
                    groupName: key
                        .split('_')
                        .map(comp => comp.charAt(0).toUpperCase() + comp.slice(1))
                        .join(' '),
                    options: data.data[key]
                })
            }
        })

        // DOCUMENTS
        this.$http.get(`documents/listDocumentsToAssign`).then(({data}) => {
            this.assignDocumentList = []
            const keys = Object.keys(data.data)
            for (const key of keys) {
                this.assignDocumentList.push({
                    groupName: key
                        .split('_')
                        .map(comp => comp.charAt(0).toUpperCase() + comp.slice(1))
                        .join(' '),
                    options: data.data[key]
                })
            }
        })

        // SALARY TYPES
        this.$http.get(`system/salaryTypes`).then(({data}) => {
            this.salaryTypes = dictToSelectArray(data.data)
        })

        // INTERVIEW DURATIONS
        this.$http.get(`system/interviewDurations`).then(({data}) => {
            this.durations = dictToSelectArray(data.data)
        });

        /*

        this.$http.get(`candidates/candidateFolders`).then(({data}) => {
            const options = data.data
            delete options.any
            delete options.not
            this.folderOptions = dictToSelectArrayWithTitle(options)
        })

        //FILTER OPTIONS AND REQUESTS
        this.$http.get(`offers/jobSkills`).then(({data}) => {
            if (data.data) {
                this.matchSkills = data.data.map(type => ({
                    value: type.id,
                    title: type.label
                }))
            }
        });

        this.$http.get(`settings/labels`).then(({data}) => {
            this.labelOptions = data.data.map(label => ({
                value: label.Id,
                title: label.Label
            }))
        })

         */

    },
    watch: {
        availableInterviews: {
            deep: true,
            handler(newInterviews) {
                if (newInterviews.length > 0) {
                    this.selectedInterview = newInterviews[newInterviews.length - 1];
                }
            }
        },

        statusChangeTemplate: {
            deep: true,
            handler(newTemplate, oldTemplate) {
                if (newTemplate && newTemplate != oldTemplate) {
                    this.statusChangeMessage = newTemplate.message
                    this.statusChangeSubject = newTemplate.subject

                }
            }
        },
        curOfferChoice: {
            deep: true,
            handler(newOffer, oldOffer) {
                if (newOffer &&
                    newOffer.value !== oldOffer.value &&
                    newOffer.value != this.selectedOfferId
                )
                    this.switchOffer(newOffer.value)
            }
        },
    },
    methods: {
        formatDate(value) {
            const dateFormat = useAuth.getDateFormat() || 'DD/MM/YYYY';
            const formatted = moment(new Date(value)).format(dateFormat);
            return formatted;
        },
        formatTime(value){
            const dateFormat = 'HH:mm';
            const formatted = moment(new Date(value)).format(dateFormat);
            return formatted;
        },
        openAddInterview(){
            this.$bvModal.show('modal-add-interview');
        },
        interviewForModal(){
            this.refresh();
            this.$bvModal.hide('modal-add-interview');
        },
        changeCandidate(direction){
          if(direction === 'next' && this.candidates[this.candidateIndex + 1].Id){
            this.switchCandidate(this.candidates[this.candidateIndex + 1].Id);
          }else if(direction === 'prev' && this.candidates[this.candidateIndex - 1].Id){
              this.switchCandidate(this.candidates[this.candidateIndex - 1].Id);
          }
        },
        navToEdit() {
            this.$router.push({
                name: 'cv-edit',
                params: {
                    id: this.candidateDetails.candidate.Id
                }
            })
        },
        navToView() {
            this.$router.push({
                name: 'cv-preview',
                params: {
                    id: this.candidateDetails.candidate.Id
                }
            })
        },
        onDelete(candidate) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result && result.isConfirmed) {
                    const formData = new FormData()
                    formData.append('candidates', candidate)

                    this.$http
                        .delete(`candidates`, formData)
                        .then(() => {
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        removeTask(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            })
                .then(result => {
                    if (result.value) {
                        this.$http.delete(`tasks?id=${task.Id}`).then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refresh()
                        })
                    }
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        switchOffer(toOffer) {
            if (toOffer && toOffer != this.lastOfferId) {
                //console.log("Next OFFER ID: ", toOffer)
                this.$http
                    .get(`offers?id=${toOffer}`)
                    .then(({data}) => {

                        let nextCandidates = Object.values(data.data.candidates_applied);
                        //console.log("ALL next candidates: ", nextCandidates);

                        if(nextCandidates.length > 0){
                            let nextCandidateId = this.$route.params.id;

                            let nextCandidateIndex = nextCandidates.map(function(e) { return e.Id; }).indexOf(parseInt(this.$route.params.id));

                            if(nextCandidateIndex === -1) {
                                nextCandidateId = nextCandidates[0].Id;
                            }
                            //console.log("SELECTED NEXT CANDIDATE ID: ", nextCandidateId);
                            router.push({name: 'view-application', params: {id: nextCandidateId}, query: {offerId: toOffer}})
                        }
                    });
            }
        },
        switchCandidate(toCandidate){
          this.$router.push(
              {name: 'view-application',
                  params: {id: toCandidate},
                  query: {offerId: this.selectedOfferId}
              }
          )
        },
        refresh() {
            let requestUrl = `candidates?id=${router.currentRoute.params.id}&offer_id=${this.selectedOfferId}`
            this.$http
                .get(requestUrl)
                .then(({data}) => {
                    this.candidateDetails = data.data;

                    if (this.candidateDetails.current_offer)
                        this.selectedOfferId = this.candidateDetails.current_offer.Id

                    if (this.selectedOfferId) {
                        let applicationArray = Object.values(this.candidateDetails.applications);
                        this.currentApplication = applicationArray.find(e => e.OfferId == this.selectedOfferId)
                        this.selectedOffer = this.candidateDetails.offers.find(e => e.Id == this.selectedOfferId)
                    } else {
                        this.currentApplication = this.candidateDetails.applications[0]
                        this.selectedOffer = this.candidateDetails.offers[0]
                    }

                    if(this.candidateDetails.tabs.interviews){

                        let defaultItem = this.candidateDetails.tabs.interviews.data[0];
                        this.selectedInterview = {
                            value: defaultItem.Id,
                            text: defaultItem.Location + ' - ' + this.formatDate(defaultItem.StartDate)+' '+this.i18nT(`at`)+' '+this.formatTime(defaultItem.StartDate)
                        };
                    }

                })
                .catch(() => {
                    // this.$http
                    //     .get(`candidates?id=${router.currentRoute.params.id}`)
                    //     .then(({data}) => {
                    //         this.candidateDetails = data.data
                    //         this.currentApplication = this.candidateDetails.applications.find(e => e.OfferId == this.selectedOfferId)
                    //         this.selectedOffer = this.candidateDetails.offers.find(e => e.Id == this.selectedOfferId)
                    //         if (this.candidateDetails.current_offer)
                    //             this.selectedOfferId = this.candidateDetails.current_offer.Id
                    //     })
                })
        },
        publishComment() {
            this.$refs.commentForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.candidateDetails.current_application.Id
                    )
                    formData.append('comment', this.comment)

                    this.$http
                        .post('candidates/addComment', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.comment = ''
                            this.$refs.commentForm.reset()
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        publishTodo() {
            this.$refs.todoForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.candidateDetails.current_application.Id
                    )
                    formData.append('todo_comment', this.todo)

                    this.$http
                        .post('candidates/addTodo', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.todo = ''
                            this.$refs.todoForm.reset()
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        publishRating() {
            this.$refs.ratingForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.candidateDetails.current_application.Id
                    )
                    formData.append('star_rating', this.rating)
                    formData.append('rating_comment', this.ratingComment)
                    formData.append('rating_type', this.ratingType)

                    this.$http
                        .post('candidates/addRating', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.ratingComment = ''
                            this.ratingType = ''
                            this.rating = 3
                            this.$refs.ratingForm.reset()
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        publishInterview() {
            this.$refs.interviewForm.validate().then(success => {
                //console.log(success)
                //console.log(this.candidateDetails)
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.candidateDetails.current_application.Id
                    )
                    // formData.append('interview_when', `${this.interviewFrom} - ${this.interviewTo}`)

                    formData.append('StartDate', this.interviewFrom+' '+this.interviewFromTime)
                    formData.append('Duration', this.duration)
                    formData.append('interview_with', this.interviewWith)
                    formData.append('interview_location', this.interviewLocation)
                    formData.append('interview_comment', this.interviewComment)

                    this.$http
                        .post('candidates/addInterview', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.interviewFrom = ''
                            this.interviewFromTime = ''
                            this.interviewTo = ''
                            this.interviewWith = ''
                            this.interviewLocation = ''
                            this.interviewComment = ''
                            this.$refs.interviewForm.reset()
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDeleteDocument(document) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .post(`documents/unassign?document_id=${document.Id}&user_id=${this.candidateDetails.candidate.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDeleteComment(comment) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`candidates/comment?id=${comment.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDeleteTodo(todo) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`candidates/todo?id=${todo.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDeleteInterview(interview) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`candidates/interview?id=${interview.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onDeleteRating(rating) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`candidates/rating?id=${rating.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refresh()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        moveStage(stage) {
            this.targetStage = stage
            console.log("Stage details:  ", stage);
            this.statusTemplateOptions =
                stage.message_templates.length > 0
                    ? stage.message_templates.map(e => (
                        {
                            value: e.id,
                            text: e.label,
                            message: e.message,
                            subject: e.subject
                        }
                        ))
                    : []

            this.statusChangeTemplate = stage.message_template
                ? stage.message_template.id
                : this.statusTemplateOptions[0]
            this.$bvModal.show('modal-change-status')
        },
        validationChangeStatus(event) {

            event.preventDefault()

            this.$refs.changeStatusForm.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append(
                        'application_id',
                        this.candidateDetails.current_application.Id
                    )

                    formData.append('stage', this.targetStage.short)
                    formData.append('stage_type', this.targetStage.type)

                    if (this.changeStatusNotification) {
                        formData.append('message', this.statusChangeMessage)
                        formData.append('subject', this.statusChangeSubject)
                    }

                    if(this.targetStage.short == 'INTERVIEW' && this.changeStatusNotification && this.selectedInterview){
                        formData.append('interview_id', this.selectedInterview.value)
                    }

                    if (this.targetStage.type === 'HIRED') {
                        formData.append('onboarding_at', this.hiringDate)
                        formData.append('evaluation_period_end', this.evaluationPeriodEnd)
                        formData.append('ending_at', this.ending)
                        formData.append('salary_net', this.salaryNet)
                        formData.append('salary_gross', this.salaryGross)
                        formData.append('salary_type', this.salaryType)
                    }

                    this.$http
                        .post('candidates/changeStage', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.$bvModal.hide('modal-change-status')
                            this.refresh()
                            this.hiringDate = null
                            this.evaluationPeriodEnd = null
                            this.ending = null
                            this.salaryNet = null
                            this.salaryGross = null
                            this.salaryType = null
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        moveFolderBulk(folder) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: `Are you sure you want to change the folder ${folder.title}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result) {
                    const formData = new FormData()
                    formData.append(
                        'candidates',
                        this.candidateDetails.current_application.Id
                    )
                    formData.append('folders', folder.value)
                    this.$http
                        .post('candidates/addToFolder', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.folders = []
                            this.$bvModal.hide('modal-add-to-folder')
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        openAssignTaskModal() {
            this.$bvModal.show('modal-add-task')
        },
        openAssignDocumentModal() {
            this.$bvModal.show('modal-add-document')
        },
        assignTasks(event) {
            event.preventDefault()
            if (this.tasks.length > 0) {
                const formData = new FormData()
                formData.append('freelancer_id', this.candidateDetails.candidate.Id)
                formData.append(
                    'tasks_to_assign',
                    this.tasks.map(task => task.Id).join(',')
                )
                this.$http
                    .post('tasks/assign', formData)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.tasks = []
                        this.$bvModal.hide('modal-add-task')

                        this.refresh()
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
            }
        },
        assignDocuments(event) {
            event.preventDefault()
            if (this.documents.length > 0) {
                const formData = new FormData()
                formData.append(
                    'application_id',
                    this.candidateDetails.current_application.Id
                )
                formData.append(
                    'documents_to_add',
                    this.documents.map(task => task.Id).join(',')
                )
                this.$http
                    .post('documents/assign', formData)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.documents = []
                        this.$bvModal.hide('modal-add-document')
                        this.refresh()
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
            }
        },
        onFinishTask(task) {
            const formData = new FormData()
            formData.append('id', task.Id)
            formData.append('completed', 1 - task.Completed)
            this.$http
                .post('tasks/completedStatus', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.refresh()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.nav-tabs .nav-link {
    align-items: start;
    justify-content: left;
}

.section-container {
    border: solid 1px #eeeeee;
    border-radius: 8px;
}

.item-rating {
    display: inline-block;
    margin-left: 6px;

    ul {
        margin-bottom: 0px;
    }
}

.info-item {
    span {
        margin-left: 6px;
    }
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;
    margin-right: 8px !important;

    .handler {
        cursor: pointer;
    }
}

.task-container {
    background-color: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    margin-top: 8px;
    padding: 12px;
    position: relative;

    .completed {
        text-decoration: line-through;
    }

    .task-type {
        font-size: 12px;
    }

    .removeBtn {
        position: absolute !important;
        right: 10px !important;
        bottom: 10px !important;
    }
}

.detail-field {
    min-height: 32px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
