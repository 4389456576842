var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:_vm.formId},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.i18nT("Date"),"label-for":"from"}},[_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"name":"date","state":errors.length > 0 ? false:null},model:{value:(_vm.interviewFrom),callback:function ($$v) {_vm.interviewFrom=$$v},expression:"interviewFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.i18nT("Interview start time"),"label-for":"from"}},[_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"minutes-step":"15","state":errors.length > 0 ? false:null},model:{value:(_vm.interviewFromTime),callback:function ($$v) {_vm.interviewFromTime=$$v},expression:"interviewFromTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.i18nT("Duration"),"label-for":"duration"}},[_c('validation-provider',{attrs:{"name":"duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.durations,"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.i18nT("Location"),"label-for":"Location"}},[_c('validation-provider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.interviewLocation),callback:function ($$v) {_vm.interviewLocation=$$v},expression:"interviewLocation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.i18nT("Participants")+" ("+_vm.i18nT("Ctrl+click to select multiple")+") ","label-for":"with"}},[_c('validation-provider',{attrs:{"name":"With","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"multiple":true,"options":_vm.candidateDetails.staff.map(function (staff) { return ({value: staff.Id, text: staff.Label}); }),"state":errors.length > 0 ? false:null},model:{value:(_vm.interviewWith),callback:function ($$v) {_vm.interviewWith=$$v},expression:"interviewWith"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.i18nT("Internal comments"),"label-for":"comment"}},[_c('b-form-textarea',{attrs:{"id":"textarea-interviewComment","placeholder":_vm.i18nT("Private notes (the candidate doesn't see these)"),"rows":"3"},model:{value:(_vm.interviewComment),callback:function ($$v) {_vm.interviewComment=$$v},expression:"interviewComment"}})],1),_c('b-button',{attrs:{"size":"","variant":"primary"},on:{"click":_vm.publishInterview}},[_vm._v(" "+_vm._s(_vm.i18nT("Publish"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }