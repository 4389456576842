<template>
  <app-timeline>
    <app-timeline-item
      v-for="(interview,index) in data"
      :key="index"
      :title="`${interview.UserName} ` +i18nT(`scheduled an interview`)"
      :subtitle="interview.Comment + ''"
      icon="CalendarIcon"
      :time="niceDate(interview.CreatedAt)"
      variant="danger"
      :data="interview"
      :onDelete="onDelete"
      :show-delete="showDelete"
      :when="trimWhen(interview.StartDate)"
      :with_="`${Object.values(interview.InteviewWithNames).join(', ')}`"
      :where="interview.Location"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'
import moment from "moment";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      default: null
    },
      showDelete: {
          type: Boolean,
          default: true
      }
  },
    methods: {
        niceDate(inputDate){
            return moment(inputDate).format("Do MMM mm:HH");
        },
        trimWhen(whenRange){
            let output = '';
            if(whenRange){
                output = whenRange.substring(0,19) + whenRange.substring(29,35);
            }
            return output;
        }
    },
}
</script>
