<template>
  <app-timeline>
    <app-timeline-item
      v-for="(education,index) in data"
      :key="index"
      :title="`${education.Course} | ${education.Institution}`"
      :subtitle="education.Description"
      icon="BookOpenIcon"
      variant="danger"
      :time="formatDate(education.StartDate)+' - '+ formatDate(education.EndDate)"
      :showDelete="false"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from './CandidateTimelineItem.vue'
import useAuth from "@/auth/useAuth";
import moment from "moment";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
      formatDate(date) {
          const dateFormat = useAuth.getDateFormat() || 'DD/MM/YYYY';
          const formatted = moment(new Date(date)).format(dateFormat);
          return formatted;
      },
  }
}
</script>
